import React from "react";
import "./RatingPrecision.scss";
import StarRatings from "react-star-ratings";

const RatingPrecision = ({ value, onRatingChange, edit, half, size }) => {
    return (
        <div className="rating_wrapper">
            <StarRatings
                rating={value}
                starSpacing="2px"
                starDimension={`${size ? size : 18}`}
                starRatedColor={"#ffd100"}
                starEmptyColor={"#cfcfcf"}
            />
        </div>
    );
};

export default RatingPrecision;
