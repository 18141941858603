import React from "react";
import FormControl from "@material-ui/core/FormControl";
import SelectComponentGreen from "../../../HelperComponents/SelectComponentGreen/SelectComponentGreen";
import privatCredit from "../../../../public/images/privat-credit.svg";
import alphaCredit from "../../../../public/images/alpha-credit.svg";
import creditInfo from "../../../../public/images/credit-info.svg";
import DialogComponent from "../../../HelperComponents/DialogComponent/DialogComponent";
import { Link } from "../../../../i18n";

const DialogCredit = ({
    onClose,
    state,
    t,
    getOption,
    setState,
    changeInstallmentCheckboxPrivat,
    changeInstallmentCheckboxAlpha,
    installmentPrivat,
    installmentAlpha,
    setInstallmentPrivat,
    setInstallmentAlpha,
    productItem
}) => {
    const installmentsPrivat = [
        { label: getOption(`2 ${t("мес")}.`), value: "1" },
        { label: getOption(`3 ${t("мес")}.`), value: "2" },
        { label: getOption(`4 ${t("мес")}.`), value: "3" },
        { label: getOption(`5 ${t("мес")}.`), value: "4" }
        // { label: getOption(`6 ${t("мес")}.`), value: "5" },
    ];

    const installmentsAlpha = [
        { label: getOption(`3 ${t("мес")}.`), value: "2" },
        { label: getOption(`5 ${t("мес")}.`), value: "4" },
        { label: getOption(`10 ${t("мес")}.`), value: "9" },
        { label: getOption(`15 ${t("мес")}.`), value: "14" }
    ];
    return (
        <DialogComponent open={state.creditDialogOpened} onClose={onClose} paper_classes="dialog_product_wrapper">
            <div className="dialog-credit">
                <div className="dialog-credit-head">{t("Варианты рассрочки")}</div>
                <div className="bank-list">
                    <div className="bank-item">
                        <label className="payment_input">
                            <input
                                name="payment_method"
                                type="radio"
                                value={0}
                                onChange={() => setState({ ...state, paymentMethod: 0 })}
                                checked={state.paymentMethod === 0}
                            />
                            <div>
                                <div>
                                    <img src={privatCredit} alt="privat-credit" />
                                </div>
                                <span>{t("Приватбанк - оплата частями")}</span>
                            </div>
                        </label>
                        <div className="bank-item-money">
                            <FormControl className="select_wrapper">
                                <SelectComponentGreen
                                    value={installmentPrivat}
                                    options={installmentsPrivat}
                                    change={changeInstallmentCheckboxPrivat}
                                    isClearable="false"
                                    isSearchable={false}
                                />
                            </FormControl>
                            <p>
                                {state.privatValue} грн/{t("мес")}.
                            </p>
                        </div>
                    </div>
                    {(productItem.discount_price > 1500 || productItem.price > 1500) && (
                        <div className="bank-item">
                            <label className="payment_input">
                                <input
                                    name="payment_method"
                                    type="radio"
                                    value={1}
                                    onChange={() => setState({ ...state, paymentMethod: 1 })}
                                    checked={state.paymentMethod === 1}
                                />
                                <div>
                                    <div>
                                        <img src={alphaCredit} alt="alpha-credit" />
                                    </div>
                                    <span>{t("Рассрочка от Альфа-Банка")}</span>
                                </div>
                            </label>
                            <div className="bank-item-money">
                                <FormControl className="select_wrapper">
                                    <SelectComponentGreen
                                        value={installmentAlpha}
                                        options={installmentsAlpha}
                                        change={changeInstallmentCheckboxAlpha}
                                        isClearable="false"
                                        isSearchable={false}
                                    />
                                </FormControl>
                                <p>
                                    {state.alphaValue} грн/{t("мес")}.
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                <div className="dialog-credit-footer">
                    <Link href={"/installment"}>
                        <a className="dialog-credit-info">
                            <span>{t("Условия кредита")}</span>
                            <img src={creditInfo} alt="credit-info" />
                        </a>
                    </Link>

                    <div
                        className="dialog-credit-btn"
                        onClick={e => {
                            e.stopPropagation();
                            localStorage.setItem(
                                "paymentType",
                                JSON.stringify({
                                    type: state.paymentMethod === 0 ? "privat" : "alpha",
                                    months:
                                        state.paymentMethod === 0
                                            ? +installmentPrivat.value + 1
                                            : +installmentAlpha.value + 1
                                })
                            );
                            addToCart(productItem, 1);
                            setState({
                                ...state,
                                creditDialogOpened: false,
                                paymentMethod: productItem.discount_price > 1500 || productItem.price > 1500 ? 1 : 0,
                                privatValue: PP_CALCULATOR.calculatePhys(
                                    4,
                                    productItem.discount_price || productItem.price
                                ).ppValue,
                                alphaValue: AlfaParts.Calculate(
                                    productItem.discount_price || productItem.price,
                                    10,
                                    "month"
                                )
                            });
                            setInstallmentPrivat({ label: getOption(`5 ${t("мес")}.`), value: "4" });
                            setInstallmentAlpha({ label: getOption(`10 ${t("мес")}.`), value: "9" });
                            router.push("/cart");
                        }}
                    >
                        {t("Перейти в корзину")}
                    </div>
                </div>
            </div>
        </DialogComponent>
    );
};

export default DialogCredit;
