import React from "react";
import DialogComponent from "../../../HelperComponents/DialogComponent/DialogComponent";
import { Link } from "../../../../i18n";
import arrow from "../../../../public/images/chevron-right.png";
import stock from "../../../../public/images/stock.svg";
import Rating from "../../../HelperComponents/Rating/Rating";
import add_cart from "../../../../public/images/add_cart.svg";
import "../ProductItem.scss";

const DialogProduct = ({
    state,
    setState,
    toggleDialog,
    toggleInnerDialog,
    handlePurchaseValue,
    handleChangeValue,
    t,
    language,
    outOfStock,
    onOrder,
    prorabFixPrice,
    addToCart
}) => {
    const handleCloseDialog = () => {
        setState({
            ...state,
            dialogOpened: false
        });
    }

    return (
        <DialogComponent open={state.dialogOpened} onClose={handleCloseDialog} paper_classes="dialog_product_wrapper">
            <div className="dialog_product">
                <div className="product_info">
                    <div className="photo">
                        <img
                            src={state.currentEl.images && state.currentEl.images[0]}
                            alt="PRORAB добавить в корзину image"
                            title="PRORAB добавить в корзину"
                        />
                        <Link href /* ={additionalURL()} as */={`/product/${state.currentEl.slug}`}>
                            <a
                                className={`exception`}
                                onClick={e => {
                                    e.stopPropagation();
                                    toggleDialog();
                                }}
                            >
                                {t("Перейти к странице товара")} <img src={arrow} alt="arrow" />
                            </a>
                        </Link>
                    </div>
                    <div className="info">
                        <div className="stock">
                            <img src={stock} alt="PRORAB stock icon" title="В наличии" />
                            <span>
                                {language === "ua"
                                    ? state.currentEl.get_availability_ua
                                    : state.currentEl.get_availability}
                            </span>
                        </div>
                        <div className="title">
                            {language === "ua" ? state.currentEl.name_ua : state.currentEl.name}
                        </div>
                        <div className="articul">
                            <Rating edit={false} value={state.currentEl.rating} />
                            <p>
                                {t("Артикул")}: {state.currentEl.article_number}
                            </p>
                        </div>
                        {outOfStock || onOrder ? null : (
                            <div className="add2cart">
                                {state.currentEl.discount_price ? (
                                    <div className="price_wrapper">
                                        <div className="prices">
                                            <span className="red">
                                                {prorabFixPrice(state.currentEl.discount_price)} грн
                                            </span>
                                            <s> {prorabFixPrice(state.currentEl.price)} грн </s>
                                        </div>
                                        <div className="input_add">
                                            <button
                                                disabled={state.productQuantity <= 1}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handlePurchaseValue("-");
                                                }}
                                                className="minus"
                                            >
                                                Minus
                                            </button>
                                            <input
                                                type="number"
                                                value={state.productQuantity}
                                                onChange={e => handleChangeValue(e)}
                                            />
                                            <button
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handlePurchaseValue("+");
                                                }}
                                                className="plus"
                                            >
                                                Plus
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="price_wrapper">
                                        <span>{prorabFixPrice(state.currentEl.price)} грн</span>
                                        <div className="input_add">
                                            <button
                                                disabled={state.productQuantity <= 1}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handlePurchaseValue("-");
                                                }}
                                                className="minus"
                                            >
                                                Minus
                                            </button>
                                            <input
                                                type="number"
                                                value={state.productQuantity}
                                                onChange={e => handleChangeValue(e)}
                                            />
                                            <button
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handlePurchaseValue("+");
                                                }}
                                                className="plus"
                                            >
                                                Plus
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="purchase">
                            {outOfStock || onOrder ? null : (
                                <button
                                    disabled={state.currentEl.get_availability !== "В наличии"}
                                    onClick={e => {
                                        e.stopPropagation();
                                        addToCart(state.currentEl, state.productQuantity);
                                        toggleDialog();
                                    }}
                                >
                                    <img
                                        src={add_cart}
                                        alt="PRORAB добавить в корзину image"
                                        title="PRORAB добавить в корзину"
                                    />
                                    {t("Купить")}
                                </button>
                            )}
                            <button
                                className={outOfStock || onOrder ? "" : "one_click_btn"}
                                onClick={e => {
                                    e.stopPropagation();
                                    toggleInnerDialog();
                                }}
                            >
                                {t(outOfStock || onOrder ? "Заказать" : "Купить в 1 клик")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </DialogComponent>
    );
};

export default DialogProduct;
