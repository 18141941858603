import React from "react";
import "./Badges.scss";
import top10Icon from "../../../public/images/top-10.svg";

const Badges = ({ product, t }) => {
    const {sale, top_sales, top_ten, discount, popularity} = product;
    
    return (
        <div className="badges_wrap">
            {!(sale || top_sales || top_ten || discount) ? (
                <>
                    {popularity &&
                    <span className="badge_item popular">{t("популярный")}</span>}
                    {product.new && <span className="badge_item new">{t("новинка")}</span>}
                </>
            ) : null}
            {sale && <span className="badge_item sale">{t("распродажа")}</span>}
            {top_sales && <span className="badge_item popular">{t("топ продаж")}</span>}
            {top_ten && (
                <span className="badge_item top-10">
                            <img src={top10Icon} alt="Топ 10" width="12px" height="12px"/>
                    {t("топ 10")}
                        </span>)
            }
            {discount && discount > 0 && (
                <span className="badge_item discount">-{discount}%</span>
            )}
        </div>
    );
};

export default Badges;
