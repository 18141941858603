import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { ButtonLoader } from "../../../HelperComponents/Loader/Loader";
import successImg from "../../../../public/images/reset-password-done.png";
import manImg from "../../../../public/images/one-click-popup.png";
import { withTranslation, Router } from "../../../../i18n";
import DialogComponent from "../../../HelperComponents/DialogComponent/DialogComponent";
import { sendRequest } from "../../../HelperComponents/functions";
import { API_BASE_URL, AppContext } from "../../../../context/appState";
import "../ProductItem.scss";

const OneClickModal = ({ open, onClose, isOrder = false, target, t, isCheckout = false }) => {
    const { register, errors, handleSubmit, setError, control } = useForm();
    const [loading, setLoad] = useState(false);
    const [succesOneClick, setSuccesOneClick] = useState(false);
    const [clicklitePhone, setClicklitePhone] = useState(false);
    const { dispatchCart, dispatch } = useContext(AppContext);

    const trackAnalytics = (cart, number_order) => {
        if (window) {
            function gtag() {
                window.dataLayer.push(arguments);
            }

            dataLayer.push({
                event: "transaction",
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: number_order,
                            revenue: (cart
                                ? cart.reduce(
                                      (x, y) =>
                                          x +
                                          (y.item.discount_price ? y.item.discount_price : y.item.price) * y.quantity,
                                      0
                                  )
                                : 0
                            ).toFixed(2)
                        },
                        products: cart.map(cartElement => ({
                            id: cartElement.item.id,
                            name: cartElement.item.name,
                            price: cartElement.price / cartElement.quantity,
                            category: cartElement.item.category_tree[cartElement.item.category_tree.length - 1].name,
                            quantity: cartElement.quantity,
                            item: cartElement.item
                        }))
                    }
                }
            });

            gtag("js", new Date());
            gtag("config", "UA-99353341-1");
        }
    };

    const submitOneClickBuy = async data => {
        let number = data.phone_number.split("-").join("");
        if (number.length !== 13) {
            return setError("phone_number", { type: "length", message: t("Номер должен состоять из 13 символов") });
        } else {
            setLoad(true);
            let value = {
                phone_number: number,
                is_checkout: isCheckout,
                products: target.map(el => ({
                    product: el.id,
                    article_number: el.article_number,
                    quantity: el.quantity,
                    amount:
                        (el.one_click_promo && el.quantity >= el.one_click_promo.min_quantity
                            ? el.one_click_promo.price
                            : el.discount_price === null
                            ? el.price
                            : el.discount_price) * el.quantity
                }))
            };
            await sendRequest(`${API_BASE_URL}/order/one-click/`, "POST", value)
                .then(response => {
                    if (response.status === 201) {
                        response.json().then(data => {
                            localStorage.setItem("number_order", data.number_order);
                            setClicklitePhone(data);
                            setSuccesOneClick(true);
                            trackAnalytics(
                                value.products.map(el => ({
                                    quantity: el.quantity,
                                    price: el.amount,
                                    item: target.find(finded => finded.id == el.product)
                                })),
                                data.number_order
                            );
                            dispatchCart([], dispatch, false);
                            Router.push(`/checkout/success`);
                        });
                    } else {
                        response.json().then(data => {
                            Object.entries(data).forEach(err => {
                                // console.log(err);
                                setError("phone_number", { type: "back_err", message: err[1] });
                                setLoad(false);
                            });
                        });
                    }
                })
                .catch(error => console.log(error.response.data));
        }
    };

    return (
        <DialogComponent
            open={open}
            onClose={onClose}
            root_classes="inner_dialog"
            paper_classes="dialog_product_one_click"
        >
            <div>
                <p className="one_click_head">{t(isOrder ? "Заказ" : "Покупка в 1 клик")}</p>
                {!succesOneClick && (
                    <p className="one_click_desc">
                        {t("Укажите Ваш номер телефона и наш менеджер свяжеться с вами для оформления заказа")}
                    </p>
                )}

                {succesOneClick ? (
                    <div className="success-wrapper">
                        <div className="img-wrapper">
                            <img src={successImg} alt="success-img" />
                        </div>
                        <p className="success-header">
                            {t(isOrder ? "Заказ отправлен в обработку" : "Заказ успешно оформлен")}
                        </p>
                        <p className="success-desc">{t("Мы свяжемся с вами для уточнения деталей")}</p>
                    </div>
                ) : (
                    <div className="clicklite_phone">
                        <form
                            onSubmit={handleSubmit(submitOneClickBuy)}
                            className="clicklite_phone__form"
                            style={clicklitePhone === false ? null : { display: "none" }}
                        >
                            <Controller
                                as={InputMask}
                                control={control}
                                mask="+3\8099-999-99-99"
                                maskChar=""
                                ref={register({
                                    required: true,
                                    pattern: { value: /^\+{1}(380\d{2})[-]\d{3}[-]\d{2}[-]\d{2}$/g },
                                    minLength: 16,
                                    maxLength: 16
                                })}
                                className={`input_field ${errors.phone_number ? "red_border" : ""}`}
                                name="phone_number"
                                placeholder="+380"
                                dafaultValue={`+380`}
                            />
                            {errors.phone_number && errors.phone_number.type === "required" && (
                                <span className="error">{t("Обязательное поле")}</span>
                            )}
                            {errors.phone_number && errors.phone_number.type === "pattern" && (
                                <span className="error">{t("Формат номера должен быть")} +380XXXXXXXXX</span>
                            )}
                            {errors.phone_number && errors.phone_number.type === "length" && (
                                <span className="error">{errors.phone_number.message}</span>
                            )}
                            {errors.phone_number && errors.phone_number.type === "back_err" && (
                                <div className="multipleErr">
                                    <span style={{ display: "block" }} className="error">{`Ой, ${t(
                                        "что-то пошло не так"
                                    )}. ${t("Попробуйте позже")}.`}</span>
                                </div>
                            )}
                            {loading ? (
                                <ButtonLoader />
                            ) : (
                                <button type="submit">{t(isOrder ? "Заказать" : "Оформить заказ")}</button>
                            )}
                        </form>
                    </div>
                )}

                <div className="img-wrapper">
                    <img src={manImg} alt="man-img" />
                </div>
            </div>
        </DialogComponent>
    );
};

export default withTranslation("product")(OneClickModal);
