import React from "react";
import ReactStars from "react-stars";

import "./Rating.scss";

const Rating = ({ value, onRatingChange, edit, half, size }) => {
    return (
        <div className="rating_wrapper">
            <ReactStars
                half={half}
                edit={edit}
                onChange={onRatingChange}
                count={5}
                size={size ? size : 22}
                value={value}
                color1="#cfcfcf"
                color2="#ffd100"
            />
        </div>
    );
};

export default Rating;
